<template>
  <div id="app" role="application">
    <main role="main">
      <router-view />
    </main>
  </div>
</template>

<style lang="scss">
@import './assets/styles/reset';
@import './assets/styles/fonts';
@import './assets/styles/variables';
@import './assets/styles/colors';
@import './assets/styles/main';
</style>
